/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  text-align: left;
  .geosuggest__input {
    width: 100%;
    border: 1px solid #ced4da;
    // border-radius: 0.25rem;
    padding: 0.5em 1em;
    -webkit-transition: border 0.2s, box-shadow 0.2s;
    transition: border 0.2s, box-shadow 0.2s;
    &:focus {
      border-color: #5acfff;
      box-shadow: 0 0 0 0.2rem rgba(0, 154, 217, 0.25);
      outline: none;
    }
  }
  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #5acfff;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
    box-shadow: 0 0 0 0.2rem rgba(0, 154, 217, 0.25);

    .geosuggest__item {
      font-size: 18px;
      font-size: 1rem;
      padding: 0.5em 0.65em;
      cursor: pointer;
      &:hover,
      &:focus {
        background: #f5f5f5;
      }

      .geosuggest__item__matched-text {
        font-weight: bold;
      }
    }
    &.geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
      box-shadow: none;
    }
  }
}

.geosuggest__item--active {
  background: #5acfff;
  color: #fff;
  &:hover,
  &:focus {
    background: #ccc;
  }
}
