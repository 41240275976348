.sign-up {
  margin-top: 0.5rem;
  border-top: 2px solid $orange;
  .title {
    margin-bottom: 0;
    text-align: center;
    font-size: 1.2rem;
  }
  .inline-login-rego {
    width: 100%;
    padding: 0.5rem;
  }
  .nav-tabs .nav-link {
    border-radius: 0;
    border: none;
    &.active {
      background: $orange;
      color: #fff;
    }
  }
}
