.stopwatch {
  .timer {
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 5rem;
    font-weight: 300;
    .sep {
      // font-size: 2em;
      font-weight: 500;
      // margin-top: -12px;
    }
  }
  .controls {
    display: flex;
    align-items: stretch;
    margin-top: 0.1rem;
    .btn {
      border: none;
      padding: 10px 15px;
      width: 100%;
      color: #fff;
      border-radius: 0;
    }
  }
  .save-time {
    margin-top: 0.5rem;
    .btn {
      width: 100%;
    }
  }
}

.save-time {
  margin-top: 0.5rem;
}
