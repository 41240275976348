.page-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #dfdfdf;
  &.default {
    background-color: $sgrey;
  }
  &.home {
    padding: 2rem 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
      url('/img/home-bg-alt.png') no-repeat;
    background-size: cover;
    background-position: 50% 30%;
    color: white;

    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
}
