.drag-list {
  border: 1px solid #ced4da;
  padding: 8px;
  .drag-item {
    display: flex;
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    align-content: flex-end;
    border: 1px solid #ced4da;
  }
  .drag-handle {
    display: flex;
    align-items: center;
    align-content: center;
    user-select: none;
    margin: -0.5rem 0.5rem -0.5rem -0.5rem;
    padding: 0.5rem;
    line-height: 1.5;
    background: #fff;
    border-right: 1px solid #ddd;
    color: #000;
  }
}
