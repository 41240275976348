.rdw-editor-toolbar {
  margin-bottom: 0;
  border-color: #ced4da;
  border-radius: 0;
}
.rdw-editor-main {
  border: 1px solid #ced4da;
  border-top: none;
  padding: 0 1rem;

  max-height: 400px;
  &.editor-has-focus {
    @extend .has-focus;
  }
}
