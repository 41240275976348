$orange: #fa4c01;
$orange-hover: #bd3902;
$sgrey: #f5f5f5;
$blue: #31a5dd;
$gray-800: #404042;
$gray-600: #939597;
$green: #54c247;
$red: hsl(12, 73%, 60%);
$primary: $blue;
.has-focus {
  border-color: #5acfff;
  box-shadow: 0 0 0 0.2rem rgba(0, 154, 217, 0.25);
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~react-redux-toastr/src/styles/index';
@import 'scss/modules';
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Arial', 'Segoe UI', sans-serif;
}
h1,
h2,
h3,
h4,
h5 {
  // font-family: 'Arimo';
}
.leaderboard-container article:nth-child(even) {
  background: #f9f9f9;
  margin: 1rem -15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .results {
    background: #fff;
  }
}
.display-5 {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}
label {
  margin-bottom: 0.05rem;
  font-weight: bold;
  font-size: 0.875rem;
}
a {
  color: $orange;
  &:hover {
    color: $orange-hover;
  }
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.card,
.custom-select,
.custom-checkbox .custom-control-label::before,
.form-control,
.btn {
  border-radius: 0;
}
.btn.btn-purple {
  background-color: var(--purple);
  border-color: var(--indigo);
  color: #fff;
  &:hover {
    background-color: var(--indigo);
  }
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
}
.equipment-card {
  .card-footer {
    padding: 0;
    .view {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    .edit {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.admin-tools-header {
  background: $blue;
  color: #f7f7f7;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  .back-link {
    color: #f7f7f7;
  }
}
.fullscreen-modal {
  &.modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    .modal-content {
      height: 100%;
      border: none;
      border-radius: 0;
    }
  }
}
