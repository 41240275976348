.footer {
  margin-top: 0.5rem;
  background: $orange;
  color: #fff;
  .footer-links {
    padding-top: 1rem;
    font-size: 0.875rem;
    a {
      color: #fff;
    }
  }
  .copyright {
    text-align: center;
    font-size: 0.875rem;
    color: #fff;
    margin: 0.5rem 0;
  }
}
