.leaderboard {
  margin-top: 0.5rem;
  &.social {
    .title {
      background: $blue;
    }
  }
  &.comp {
    .title {
      background: $red;
    }
  }
  .title {
    svg {
      color: #fff;
    }
    padding: 0.75rem 1.25rem;
    font-weight: bold;
    color: #fff;
  }
  .results {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    .lb-result {
      td {
        vertical-align: middle;
      }
      height: 46px;
      .place {
        text-align: center;
      }
      .profile-data {
        display: flex;
        align-items: center;
        .profile-image {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #dfdfdf;
          margin-right: 1.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border-radius: 50%;
          }
        }
        svg {
          width: 75%;
          height: 75%;
          path {
            fill: #f9f9f9;
          }
        }
      }
    }

    // td,
    // th {
    //   width: 20%;
    // }
    // td:first-of-type,
    // th:first-of-type {
    //   width: 10%;
    // }
    // td:last-of-type,
    // th:last-of-type {
    //   width: 20%;
    // }
    .table {
      margin-bottom: 0;
    }
    .no-times {
      padding: 8px;
      text-align: center;
    }
  }
}
