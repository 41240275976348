.event-card {
  .card-header {
    padding: 0.5rem;
    p {
      margin-bottom: 0;
      font-size: 0.875rem;
    }
  }
  .card-body {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .content {
      max-height: 70px;
      overflow: hidden;
      flex: 1 1 auto;
    }
    .btn {
      margin-top: 0.5rem;
    }
  }
  .card-footer {
    padding: 0.5rem;
    text-decoration: none;
  }
}
