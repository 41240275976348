.navbar {
  background-color: $orange;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  .navbar-brand {
    font-size: 1.9rem;
    color: #fff;
    padding-left: 2rem;
    small {
      font-size: 14px;
    }
    #aspace-logo {
      width: 78px;
      height: 21px;
      path {
        fill: #fff;
      }
    }
    .by {
      position: relative;
    }
  }
  .nav-item {
    .nav-link {
      color: #fff;
      font-size: 1rem;
      position: relative;
      &.nav-btn {
        background: #fff;
        color: $orange;
        padding-top: 0.25rem;
        margin-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
        margin-left: 15px;
      }
      &.active,
      &:hover {
        &:after {
          width: calc(90%);
        }
      }
      &:after {
        height: 2px;
        background: #fff;
        content: '';
        width: 0;
        position: absolute;
        transform: translateX(-50%);
        transition: width 0.4s;
        transition-timing-function: cubic-bezier(1, -0.65, 0, 2.31);
        left: 50%;
        margin-top: 2rem;
      }
    }
  }
  .dropdown-menu {
    border-radius: 0;
    .nav-link {
      color: $orange;
    }
  }
  @media (max-width: 991px) {
    .navbar-brand {
      padding-left: 0.35rem;
    }
    .navbar-toggler {
      border: none;
      .icon-bar {
        border: none;
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        margin: 4px 0 4px 0;
        transition: all 0.35s;
        background: #fff;
      }
      &:focus {
        outline: none;
      }
      &.open {
        .icon-bar {
          &:nth-of-type(1) {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
          }
          &:nth-of-type(2) {
            opacity: 0;
            filter: alpha(opacity=0);
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
          }
        }
      }
    }
    .navbar-collapse {
      position: absolute;
      top: 90px;
      left: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 100%;
      .navbar-nav {
        flex-direction: row;
        flex-wrap: wrap;
        .nav-item {
          text-align: left;
          padding: 0.75rem 2rem 0.5rem;
          width: 100%;
          .nav-btn {
            margin-left: 0;
          }
          .nav-link {
            padding-left: 0.5rem;
            &.active,
            &:hover {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
      &.collapsing {
        height: auto;
        transition: all 0.15s ease-in;
        left: -100%;
      }
      &.show {
        left: 0;
        height: calc(100vh - 54px);
        z-index: 10;
        background: rgba(10, 10, 10, 0.85);
        transition: all 0.15s linear;
      }
    }
    .dropdown-menu {
      background: transparent;
      border: none;
      .dropdown-item {
        padding-left: 0;
        .nav-link {
          padding: 0;
          color: #fff;
        }
      }
    }
  }
}
.nav-tabs .nav-link {
  border-radius: 0;
  border: none;
  &.active {
    background: $orange;
    color: #fff;
  }
  &:hover {
    background: $orange;
    color: #fff !important;
  }
}
