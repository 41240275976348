.loc-equip {
  .card {
    border-radius: 0;
    border: none;
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    .eq-label {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      background: rgba(0, 0, 0, 0.65);
      color: #fff;
      font-size: 1.25rem;
      font-weight: 300;
      cursor: default;
      max-height: 100%;
      transition: padding 0.25s ease-in-out;
    }
    &:hover {
      transform: scale(1.1);
      .eq-label {
        padding: 5rem 0;
      }
    }
  }
}
