.btn-group-lg > .btn {
  border-radius: 0;
}
.btn {
  &.btn-primary {
    background: $orange;
    border-color: $orange;
    &:active,
    &:hover {
      background: $orange-hover;
      border-color: $orange-hover;
    }
  }
}
