.location-card {
  .card-body {
    padding: 0;
  }
  .card-body,
  .card-footer {
    padding: 0.5rem;
    text-decoration: none;
    .loc-name {
      font-weight: bold;
    }
    .loc-addr {
      font-size: 0.875rem;
    }
  }
}
