.react-date-picker,
.react-datetime-picker {
  width: 100%;
  &.react-date-picker--open,
  &.react-datetime-picker--open {
    .react-date-picker__wrapper,
    .react-datetime-picker__wrapper {
      @extend .has-focus;
    }
  }
  .react-date-picker__wrapper,
  .react-datetime-picker__wrapper {
    border: 1px solid #ced4da;
    width: 100%;
    .react-date-picker__inputGroup,
    .react-datetime-picker__inputGroup {
      padding: 0.375rem 0.75rem;
      .react-date-picker__inputGroup__input,
      .react-datetime-picker__inputGroup__input {
        &.react-date-picker__inputGroup__month,
        &.react-datetime-picker__inputGroup__month {
          min-width: 10px !important;
        }
        &.react-date-picker__inputGroup__year,
        &.react-datetime-picker__inputGroup__year {
          width: 38px !important;
        }
        &.react-datetime-picker__inputGroup__minute {
          width: 20px !important;
        }
      }
      &.react-date-picker__inputGroup__input,
      &.react-datetime-picker__inputGroup__input {
        min-width: 0.8rem;
      }
    }
  }
}
